import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

export default function CaseStudy({ data }) {
  const caseStudyData = data.caseStudy.nodes[0].frontmatter;
  const footerData = data.footer.frontmatter;

  return (
    <div>
      <Helmet defer={false}>
        <title>{caseStudyData.title}</title>
      </Helmet>
      <div>Under construction</div>
      <Footer footerData={footerData} />
    </div>
  );
}

export const query = graphql`
  query CaseStudyQuery {
    caseStudy: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "content/case-study/" } }
    ) {
      nodes {
        frontmatter {
          title
          description
        }
      }
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/footer.md/" }) {
      frontmatter {
        footerLine1
        mailTo
        telephone
      }
    }
  }
`;
